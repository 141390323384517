<template>
  <div class="container">
	<!-- 设置默认交易所 -->
	<common-head url="/securityCenter" :title="$t('tba.defaultExchange')" :is-return="true"></common-head>
	
	<div class="layoutBox bgWhite">
		<van-radio-group v-model="exchange">
			<div class="list-radio-item" @click="exchange = 'huobi'">
				<div class="label">
					<div class="icon"><img src="@/assets/imgs/icon/api-huobi.png" alt=""></div>
					{{ $t('tba.huobi') }}
				</div>
				<van-radio name="huobi">
					<template #icon="props">
					  <img
					    class="img-icon"
					    style="width: 0.32rem;display: block;"
					    :src="
					      require('@/assets/imgs/icon/' +
					        (props.checked ? 'icon_choose' : 'icon_unchoose') +
					        '.png')
					    "
					  />
					</template>
				</van-radio>
				<!-- <van-radio name="huobi" /> -->
			</div>
			<div class="list-radio-item" @click="exchange = 'bian'">
				<div class="label">
					<div class="icon"><img src="@/assets/imgs/icon/api-bian.png" alt=""></div>
					{{ $t('tba.bian') }}
				</div>
				<van-radio name="bian">
					<template #icon="props">
					  <img
					    class="img-icon"
					    style="width: 0.32rem;display: block;"
					    :src="
					      require('@/assets/imgs/icon/' +
					        (props.checked ? 'icon_choose' : 'icon_unchoose') +
					        '.png')
					    "
					  />
					</template>
				</van-radio>
				<!-- <van-radio name="bian" /> -->
			</div>
			<div class="list-radio-item" @click="exchange = 'okex'">
				<div class="label">
					<div class="icon"><img src="@/assets/imgs/icon/api-okex.png" alt=""></div>
					{{ $t('tba.OKEx') }}
				</div>
				<van-radio name="okex">
					<template #icon="props">
					  <img
					    class="img-icon"
					    style="width: 0.32rem;display: block;"
					    :src="
					      require('@/assets/imgs/icon/' +
					        (props.checked ? 'icon_choose' : 'icon_unchoose') +
					        '.png')
					    "
					  />
					</template>
				</van-radio>
				<!-- <van-radio name="okex" /> -->
			</div>
		</van-radio-group>
		
		<div class="comBtnStyle" @click="setObject" style="margin-top: 7rem;">{{ $t('tba.save') }}</div>
	</div>
	
  </div>
</template>

<script>
import commonHead from '@/components/commonHead';

export default {
  name: "defaultExchange",
  components: { 
	  commonHead,	  
  },
  data() {
    return {
      phone: "", // 手机号
      sms: "", // 短信验证码
      flag: false, // 获取验证码 是否禁用
      count: 60, // 60s 倒计时
      timer: null, // 倒计时的 定时器
      sid: "", // 验证
      bindPhone: false,
      openid: "",
      email: "",
      vcode_method: "sms",
      exchange: "",
    };
  },
  created() {
    this.initData();

    // this.$dialog.alert({
    //     message: this.openid + ":" + this.bindPhone
    // });
  },
  methods: {
    initData() {
      this.$post2("user/api/index/getMetaInfo", {})
        .then((res) => {
          this.email = res.email;
          this.phone = res.phone;
          this.exchange = res.exchange;
          this.vcode_method = res.vcode_method;
        })
        .catch((e) => {
          this.$toast.fail(e);
        });
    },
    // onClickLeft  点击返回“设置”页面
    onClickLeft() {
      this.$router.push("uSet");
    },

    // 设置默认交易所
    setObject() {
      this.$post2("user/api/index/setDefaultExchange", {
        exchange: this.exchange,
      })
        .then((res) => {
          this.$toast.success(this.$t("tba.savesucc"));
          //  this.$router.go(-1);
          var userInfo = JSON.parse(localStorage.userInfo);
          userInfo['default_exchange'] = this.exchange;
          localStorage.userInfo = JSON.stringify(userInfo);
        })
        .catch((err) => {
          this.$toast(err);
        });
    },
  },
};
</script>

<style scoped lang="less">
.backgrondRoade {
  width: 0.6rem;
  background: black;
  height: 0.6rem;
}

.duihaoImg {
  width: 0.3rem;
  margin-left: 5.6rem;
}

/* @import "../../assets/css/puliceStyle.css"; */
.body1StyleInputStyle {
  border: 0px;
}

.bodyStyleDivStyleP {
  margin-top: 0.3rem;
  margin-bottom: 0.2rem;
}

.bodyStyleDivStyle {
  border-bottom: 1px solid #fbfbfd;
  padding-bottom: 0.3rem;
}

.body1Style {
  padding: 0.3rem;
}


.title {
  background: #f6f8f9;
  height: 2rem;
  padding: 0.3rem;
}

.bottomBtnStyle {
  width: 100%;
  background: #e1c364;
  border-radius: 5px;
  border: 0px;
}

.titleGuanLiStyle {
  font-size: 0.4rem;
  padding-top: 1.5rem;
}

.titlePStyle {
  margin-bottom: 0.2rem;
  margin-top: 0.3rem;
  padding-top: 0.1rem;
  transform: rotate(180deg);
}

/* ---------20200411[start]------- */
.layoutBox {
	padding: 0 .3rem;
}

.list-radio-item {
	padding: .2rem;
	height: 1.2rem;
	border-bottom: 1px solid #EDEDED;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.label {
		color: #373737;
		font-size: .28rem;
		display: flex;
		align-items: center;
	}
	.icon {
		width: .7rem;
		height: .7rem;
		margin-right: .15rem;
		img {
			display: block;
			width: 100%;
		}
	}
}
/* ---------20200411[end]------- */
</style>
